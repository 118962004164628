import BackpackUpgradeImg from './backpack-upgrade.png';

const BackpackUpgrade = {
  name: 'Backpack Upgrade',
  type: 'upgrade::backpack',
  slots: 4,
  image: BackpackUpgradeImg,
  value: 50
};

export default BackpackUpgrade;
