import SteelArmorImg from './steel-armor.png';

const SteelArmor = {
  name: 'Steel Armor',
  type: 'armor::body',
  defence: 14,
  image: SteelArmorImg,
  value: 150
};

export default SteelArmor;
