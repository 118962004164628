import LeatherBootsImg from './leather-boots.png';

const LeatherBoots = {
  name: 'Leather Boots',
  type: 'armor::boots',
  defence: 2,
  image: LeatherBootsImg,
  value: 25
};

export default LeatherBoots;
