const tiles = [
  [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
  [6, 4, 0, 0, 6, 6, 6, 6, 6, 0, 3, 6, 6, 6, 6, 6, 6, 6, 6, 6],
  [6, 0, 0, 0, 6, 6, 6, 6, 6, 0, 0, 6, 6, 6, 6, 6, 6, 6, 6, 6],
  [6, 0, 0, 0, 6, 6, 6, 6, 6, 0, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
  [6, 20, 0, 20, 6, 6, 6, 6, 6, 0, 6, 6, 6, 6, 6, 6, 0, 0, 0, 6],
  [6, 6, 0, 6, 6, 6, 6, 0, 0, 0, 6, 6, 6, 6, 6, 6, 0, 2, 0, 6],
  [6, 6, 0, 6, 6, 6, 6, 0, 6, 6, 6, 6, 6, 6, 6, 6, 0, 0, 0, 6],
  [6, 6, 0, 6, 6, 6, 6, 0, 6, 6, 6, 6, 6, 6, 6, 6, 6, 0, 6, 6],
  [6, 6, 0, 0, 0, 0, 0, 0, 6, 6, 6, 6, 6, 6, 6, 6, 6, 0, 6, 6],
  [6, 6, 0, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 0, 6, 6],
  [6, 6, 0, 6, 6, 6, 6, 6, 6, 6, 0, 4, 0, 0, 0, 0, 0, 0, 6, 6],
  [6, 6, 0, 6, 6, 6, 6, 6, 6, 6, 0, 0, 0, 0, 0, 0, 0, 0, 6, 6],
  [6, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 6],
  [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
  [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]
];

const monsters = [
  {
    type: 'goblin',
    position: [15, 10]
  },
  {
    type: 'goblin',
    position: [12, 11]
  },
  {
    type: 'goblin',
    position: [14, 12]
  },
  {
    type: 'stone-golem',
    position: [2, 2]
  }
];

const stairs = {
  down: '1_3',
  up: '1_5'
};

export default {
  tiles,
  monsters,
  stairs
};
