import LichBaneImg from './lich-bane.png';

const LichBane = {
  name: 'Lich Bane',
  type: 'weapon',
  range: 'melee',
  damage: 25,
  bonus: 'lich::2',
  image: LichBaneImg,
  value: 666
};

export default LichBane;
