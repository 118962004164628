import HpPotionImg from './hp-potion.png';

const HpPotion = {
  name: 'HP Potion',
  type: 'potion',
  hp: 15,
  image: HpPotionImg,
  value: 40
};

export default HpPotion;
