import SteelBootsImg from './steel-boots.png';

const SteelBoots = {
  name: 'Steel Boots',
  type: 'armor::boots',
  defence: 6,
  image: SteelBootsImg,
  value: 80
};

export default SteelBoots;
