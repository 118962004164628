import GreatHpPotionImg from './great-hp-potion.png';

const GreatHpPotion = {
  name: 'Great HP Potion',
  type: 'potion',
  hp: 40,
  image: GreatHpPotionImg,
  value: 80
};

export default GreatHpPotion;
