import LeatherCapImg from './leather-cap.png';

const LeatherCap = {
  name: 'Leather Cap',
  type: 'armor::helmet',
  defence: 3,
  image: LeatherCapImg,
  value: 30
};

export default LeatherCap;
