import SteelPantsImg from './steel-pants.png';

const SteelPants = {
  name: 'Steel Pants',
  type: 'armor::pants',
  defence: 12,
  image: SteelPantsImg,
  value: 120
};

export default SteelPants;
