import LeatherPantsImg from './leather-pants.png';

const LeatherPants = {
  name: 'Leather Pants',
  type: 'armor::pants',
  defence: 4,
  image: LeatherPantsImg,
  value: 35
};

export default LeatherPants;
