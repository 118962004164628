import SteelGlovesImg from './steel-gloves.png';

const SteelGloves = {
  name: 'Steel Gloves',
  type: 'armor::gloves',
  defence: 6,
  image: SteelGlovesImg,
  value: 80
};

export default SteelGloves;
