import LeatherGlovesImg from './leather-gloves.png';

const LeatherGloves = {
  name: 'Leather Gloves',
  type: 'armor::gloves',
  defence: 2,
  image: LeatherGlovesImg,
  value: 25
};

export default LeatherGloves;
